





































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import Multiselect from "vue-multiselect";
import {mapActions} from 'vuex'
import i18n from "@/i18n/i18n";
export default Vue.extend({
  components: {
    Layout,
    Multiselect,
  },
  created() {
    if (this.notifications.length < 1) {
      this.$router.push("/notifications");
    }
    let obj = this.notifications.find(item => item.id == this.$route.params.id);
    if (obj) {
      this.notification = JSON.parse(JSON.stringify({...obj}));
      this.editObj.clientZoneNotificationImportance =
        this.notification_importance.find(
          item => item.val == obj.clientZoneNotificationImportance
        );
      this.editObj.notificationIntervalInDays =
        this.notification_repitions.find(
          item => item.val == obj.notificationIntervalInDays
        );
      this.editObj.clientZoneNotificationType =
        this.notification_titles.find(
          item => item.val == obj.clientZoneNotificationType
        );
    } else {
      this.$router.push("/notifications");
    }
  },
  methods: {
    ...mapActions("azNotificationModule",{
      updateNotifications: "UPDATE_NOTIFICATIONS",
    }),
    editorInit() {
      if(this.$route.params.lang=='English'){
        this.$refs.tmen.editor.setContent(this.notification.clientZoneNotificationDescriptionEn);
      }
      else{

        this.$refs.tmsk.editor.setContent(this.notification.clientZoneNotificationDescriptionSk);
      }
    },
    async updateNotification() {
      await this.updateNotifications({data:{
          clientZoneNotificationType: this.editObj.clientZoneNotificationType.val,
          clientZoneNotificationNameSk:
          this.notification.clientZoneNotificationNameSk,
          clientZoneNotificationNameEn:
          this.notification.clientZoneNotificationNameEn,
          clientZoneNotificationDescriptionSk:
          this.notification.clientZoneNotificationDescriptionSk,
          clientZoneNotificationDescriptionEn:
          this.notification.clientZoneNotificationDescriptionEn,
          clientZoneNotificationImportance:
          this.editObj.clientZoneNotificationImportance.val,
          notificationIntervalInDays: this.editObj.notificationIntervalInDays.val,
        },
        id:this.$route.params.id})
      this.fetchNotifications({page:1})
      this.$router.push('/notifications')
    },
  },
  computed:{
    labelSk(): string{
      return this.$i18n.t('Title') + ' (SK)';
    },
    labelEn(): string{
      return this.$i18n.t('Title') + ' (EN)'
    },
    descriptionSk(): string{
      return this.$i18n.t('Description') + ' (SK)';
    },
    descriptionEn(): string{
      return this.$i18n.t('Description') + ' (EN)';
    },
    levelOfImportance(): string{
      return this.$i18n.t('Level of Importance');
    },
    repetition(): string{
      return this.$i18n.t('Repetition');
    },
    selectLabel(){
      return this.$i18n.t("Click to select")
    },
    deselectLabel(){
      return this.$i18n.t('Click to select')
    },
    selectedLabel(){
      return this.$i18n.t("Selected")
    }
  },
  data() {
    return {
      showModal: false,
      editObj: {
        clientZoneNotificationType: { val: 1, name: "MISSING COMPANY LOGO" },
        clientZoneNotificationImportance: { val: 1, name: "Critical" },
        notificationIntervalInDays: { val: 3, name: "3 Days" },
      },
      notification: {
        clientZoneNotificationNameEn: "",
        clientZoneNotificationNameSk: "",
        clientZoneNotificationDescriptionSk: "",
        clientZoneNotificationDescriptionEn: "",
      },
    };
  },
});
